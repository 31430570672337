/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/themes/remax-theme.module.css';

const propTypes = {
    className: PropTypes.string,
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        // eslint-disable-next-line react/forbid-prop-types
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    className: null,
    containerRef: null,
};

function RemaxTheme({ children, className, containerRef, ...props }) {
    return (
        <div className={classNames([className, styles.container])} ref={containerRef} {...props}>
            {children}
        </div>
    );
}

RemaxTheme.propTypes = propTypes;
RemaxTheme.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <RemaxTheme containerRef={ref} {...props} />);
